<template>
    <div class="homeMain">
        <div class="swiper-container banan">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in 4" :key="index">
                    <img src="@/assets/img/banner1.png" alt="" />
                    <div class="banner-content">
                        <div class="banner-content-left">
                            <h2>一体式{{ index }}</h2>
                            <h1>超级平板显微镜</h1>
                            <p>具有测量，细胞就算等功能的软件安装 9.7英寸IPS Retina屏幕 | 分辨率2048*153</p>
                            <div class="banner-content-detail">
                                {{ $t('indexBannerName') }}  <span><img src="@/assets/img/youjiantouW.png" alt="" /></span>
                            </div>
                        </div>
                        <div class="banner-content-right">
                            <img src="@/assets/img/banner_1.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pag-container">
                <div class="swiper-pag">
                    <span :class="[index==swiperIndex?'pagActive':'']" v-for="(item,index) in 4" :key="index"></span>
                </div>
            </div>
            
        </div>
        <!--  -->
        <div class="homeSection">
            <div class="product"  data-aos="fade-up"  data-aos-once='true'>
                <div class="product-head">
                    <div class="product-head-l">
                        <p>Product New trend</p>
                        <h1>{{ $t('indexHotName') }}</h1>
                    </div>
                    <div class="product-head-r">
                        <span>{{ swiper2Index+1 }}/{{ hostList.length }}</span>
                        <div class="left" :class="[swiper2Index>0?'btnActive':'']" @click="changePre2">←</div>
                        <div class="right" :class="[swiper2Index<hostList.length-1?'btnActive':'']" @click="changeNext2">→</div>
                    </div>
                </div>
                <div class="swiper-container product-banan swiper-no-swiping">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(v,i) in hostList" :key="index" @click="routerProduct(v.id,v)">
                            <div class="banner-content">
                                <div class="banner-content-left">
                                    <h2>{{ i<9?'0'+(i+1):(i+1) }}</h2>
                                    <!-- <h3>{{ v.series_name }}</h3> -->
                                    <h1>{{ $i18n.locale=='en'?v.series_name_en:v.series_name }}</h1>
                                    <p>{{v.desc}}</p>
                                </div>
                                <div class="banner-content-right">
                                    <img :src="v.icon" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="industry" data-aos="fade-up"  data-aos-once='true'>
                <div class="industry-head">
                    <div class="industry-head-l">
                        <p>APPLICATIONS</p>
                        <h1>{{ $t('indexIndustryName') }}</h1>
                    </div>
                    <div class="industry-head-r" @click="showMore">
                        {{ $t('more') }} <span><img src="@/assets/img/youjiantouW.png" alt="" /></span>
                    </div>
                </div>
                <div class="industry-list">
                    <div class="industry-item" v-for="(v,i) in $tm('indexIndustryList')" :key="i" @click="routerIndustry(i+1)">
                        <div class="industry-item-bg">
                            <img :src="require(`@/assets/img/industry${i+1}.png`)" alt="" />
                        </div>
                        <div class="industry-item-content">
                            <h1 :class="[$i18n.locale=='en'?'h1Eng':'']">{{v.chinese}}</h1>
                            <p v-if="$i18n.locale=='zh'">{{ v.english }}</p>
                            <div class="next"><img src="@/assets/img/youjiantou.png" alt="" /></div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- <div class="new" data-aos="fade-up"  data-aos-once='true'>
                <div class="new-head">
                    <p>Corporate news</p>
                    <h1>{{ $t('companyNews') }}</h1>
                </div>
                <div class="new-list">
                    <div class="new-item" v-for="(v,i) in 2" :key="i" @click="routerNew(i)">
                        <div class="new-item-l">
                            <img src="@/assets/img/new1.png" alt="" />
                        </div>
                        <div class="new-item-r">
                            <div class="new-itemR-head">
                                <span class="head-new">New</span>
                                <span class="head-next"><img src="@/assets/img/youjiantou.png" alt=""/></span>
                            </div>
                            <div class="new-itemR-time">
                                2022-09-08
                            </div>
                            <p>恭贺仰望科技2018年会盛宴圆望科技2018年会盛宴圆</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="serve" data-aos="fade-up"  data-aos-once='true'>
                <div class="serve-bg">
                    <img src="@/assets/img/serve-bg.png" alt=""/>
                </div>
                <div class="serve-content">
                    <div class="serve-l">
                        <img src="@/assets/img/logo.png" alt="" />
                    </div>
                    <div class="serve-r">
                        <h1>{{ $t('indexServeTitle') }}</h1>
                        <p>ShenZhen YANGWANG Technology company</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/utils/request.js';
export default {
    name:'home',
    data(){
        return{
            swipers:{},
            swiper2:{},
            swiperIndex:0,
            swiper2Index:0,
            textBookIndex:0,
            changeShow:true,
            hostList:[],
            industryList:[
                {
                    chinese:'科学研究',
                    english:'Scientific Research',
                },
                {
                    chinese:'显微镜应用',
                    english:'Microscope Application',
                },
                {
                    chinese:'工业应用',
                    english:'Dustrial Application',
                },
                {
                    chinese:'教学研究',
                    english:'Teaching Research',
                },
            ],

        }
    },
    mounted(){
        let that = this;
        this.getHotSeries()
        this.swipers = new Swiper('.banan', {
            autoplay: false,
            delay:5000,
            loop:true,
            on:{
                slideChangeTransitionEnd:function(){
                    that.swiperIndex = this.realIndex;
                }
            }
        }); 
    },
    methods:{
        // 热门产品
        getHotSeries(){
            let that = this;
            API.hotSeries().then(res=>{
                if(res.code==200){
                  this.hostList = res.data;
                  this.swiper2 = new Swiper('.product-banan', {
                        // loop: true,
                        stopOnLastSlide:true,
                        normalizeSlideIndex:false,
                        delay:8000,
                        // autoplay:true,
                        slidesPerView : 2,
                        spaceBetween: 61.8,
                        on:{
                            slideChangeTransitionEnd(swiper){
                                console.log(swiper.activeIndex)
                                that.$nextTick(()=>{
                                    that.swiper2Index = swiper.activeIndex
                                })
                                
                            }
                        }
                    });
                }
            })
        },
        showMore(){
            this.$parent.$parent.showNav(3);
        },
        changeTextBook(n){
            if(this.textBookIndex==n) return false;
            this.textBookIndex = n
            this.changeShow = false;
            setTimeout(() => {
                this.changeShow = true;
            }, 100);
        },
        changePre1(){
            this.swipers.slidePrev()
        },
        changeNext1(){
            this.swipers.slideNext()
        },
        changePre2(){
            this.swiper2.slidePrev()
        },
        changeNext2(){
            if(this.swiper2Index>=this.hostList.length-2){
                this.swiper2.slideTo(0)
            }
            else{
                this.swiper2.slideNext()
            } return false;
            
        },
        routerProduct(id,v){
            let name = {zh:v.series_name,en:v.series_name_en};
            sessionStorage.setItem("productName",JSON.stringify(name))
            this.$router.push({path:'/product',query:{id:id}})
        },
        routerIndustry(n){
          let path = '';
          switch(n){
                case 1:
                    path = '/page3'
                    break;
                case 2:
                    path = '/page2'
                    break;
                case 3:
                    path = '/page1'
                    break;    
                case 4:
                    path = '/page4'
                    break;      
                default:
                    break;
            }
          this.$router.push({path:`/industry${path}`})
        },
        routerNew(i){
            this.$router.push({path:'./news'});
        },
    }
}
</script>
<style lang="scss">
.homeMain{
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    .banan{
        width: 100%;
        height: 606px;
        position: relative;
        overflow: hidden;
        .swiper-slide{
            width: 100%;
            height: 100%;
            position: relative;
            img{
                width: 100%;
                height: 100%;
            }
            .banner-content{
                position: absolute;
                width: 100%;
                height: 100%;
                top: r(0);
                left: r(0);
                @include display(bottom,center);
                .banner-content-left{
                    h2{
                        line-height: 58px;
                        font-size: 40px;
                        font-weight: 400;
                        color: #fff;
                    }
                    h1{
                        font-size: 60px;
                        line-height: 87px;
                        color: #fff;
                        margin-top: 3px;
                    }
                    p{
                        line-height: 32px;
                        font-size:18px;
                        color:#F7F9FC;
                        padding: 13px 0 35px;
                        width: 344px;
                        word-break:keep-all;
                    }
                    .banner-content-detail{
                        width: 200px;
                        height: 60px;
                        line-height:60px;
                        color: #F4F7FD;
                        font-size: 25px;
                        background-color: #E15100;
                        margin-bottom: 102px;
                        @include display(center,center);
                        span{
                            margin-left: r(18);
                            img{
                                width: 37px;
                                height: auto;
                            }
                        }
                    }
                }
                .banner-content-right{
                    margin-left: 89px;
                    width:749px;
                    height: 595.83px;
                    img{
                        // background-color: rgba(59, 205, 13, .3);
                    }
                }
            }
        }
        .pag-container{
            position: absolute;
            left: 0;
            bottom: 48px;
            width: 100%;
            @include display(center,center);
            z-index: 8;
            .swiper-pag{
                width: 1200px;
                display: flex;
                @include display(center,left);
                
                span{
                    width: 9px;
                    height: 9px;
                    background-color: #fff;
                    margin-right: 28px;
                    border-radius: 50%;
                }
                .pagActive{
                    background: #E15100;
                }
            }
        }
        
    }
    .homeSection{
        width: 100%;
        background-color:#F0F4FA;
        .product{
            width: 100%;
            height: 848px;
            background-image: url('../../assets/img/product_bg.png');
            background-repeat: no-repeat;
            background-position:604px 0;
            background-size: auto 100%;
            // padding-left: r(150);
            box-sizing: border-box;
            // 
            overflow: hidden;
            &-head{
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                // padding-right: r(51);
                box-sizing: border-box;
                &-l{
                    p{
                        line-height:43px;
                        font-size:30px;
                        color: #E15100;
                        padding:100px 0 7px;
                    }
                    h1{
                        font-size: 60px;
                        color:#273443;
                        line-height: 87px;
                        font-weight: 700;
                    }
                }
                &-r{
                    display: flex;
                    align-items: center;
                    margin-bottom: 6px;
                    span{
                        font-size: 26px;
                        line-height: 42px;
                        color: rgba(0,0,0,.5);
                        
                    }
                    .left{
                        width: 58px;
                        height: 58px;
                        border: 1px solid #E15100;
                        font-size: 25px;
                        color: #E15100;
                        margin: 0 35px 0 34px;
                        text-align: center;
                        line-height:58px;
                        cursor: pointer;
                    }
                    .right{
                        width: 58px;
                        height: 58px;
                        border: 1px solid #E15100;
                        font-size: 25px;
                        color: #E15100;
                        text-align: center;
                        line-height: 58px;
                        cursor: pointer;
                    }
                    .btnActive{
                        background: #E15100;
                        color: #fff;
                    }
                }
            }
            &-banan{
                width:1200.2px;
                height: 457px;
                overflow: hidden;
                margin: 84px auto 0;
                .swiper-slide{
                    width: 569.2px;
                    height: 100%;
                    background-color: #fff;
                    .banner-content{
                        width: 100%;
                        height: 100%;
                        position: relative;
                        @include display(top,between);
                        padding: 0 20.2px 0 26.61px;
                        box-sizing: border-box;
                        cursor: pointer;
                        &-left{
                            width: 220px;
                            h2{
                                font-size:46px;
                                line-height: 67px;
                                color: #E15100;
                                font-weight: 900;
                                padding: 25px 0 0 0;
                            }
                            h3{
                                line-height:58px;
                                font-size: 40px;
                                font-weight: 300;
                                margin:35px 0 0;
                                color: #273443;
                            }
                            h1{
                                margin:35px 0 0;
                                line-height: 58px;
                                width: 150px;
                                font-size:40px;
                            }
                            p{
                                width: 100%;
                                margin-top:35px;
                                font-size:24px;
                                color:rgba(0,0,0,.5);
                                line-height:43px;
                                @include textOF(3); 
                            }
                        }
                        &-right{
                            width: 264px;
                            height: 284px;
                            margin-top: 79px;
                            @include display(center,center);
                            img{
                                width: auto;
                                height: auto;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }
        .industry{
            width: 100%;
            background-color: #fff;
            height:880px;
            box-sizing: border-box;
            &-head{
                width:1200px;
                margin: 0 auto;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                box-sizing: border-box;
                &-l{
                    p{
                        line-height:43px;
                        font-size: 30px;
                        font-weight: 700;
                        padding:100px 0 7px;
                    }
                    h1{
                        line-height:87px;
                        font-size: 60px;
                        color: #273443;
                    }
                }
                &-r{
                     width: 220px;
                     height: 60px;
                     line-height: 60px;
                     text-indent:32px;
                     display: flex;
                     align-items: center;
                     margin-bottom: 6px;
                     background-color: #E15100;
                     color: #F7FCFF; 
                     font-size:25px;
                     cursor: pointer;
                     span{
                        width:52px;
                        margin-left: 16px;
                         img{
                            display: block;
                            width: 100%;
                         }  
                     }
                }
            }
            &-list{
                width: 1209.81px;
                height: 490.38px;
                overflow-y: hidden;
                overflow-x: auto;
                white-space: nowrap;
                margin:64px auto 0;
                .industry-item{
                    width: 285px;
                    height: 100%;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    background-color: rgba(4,12,46,0.8);
                    // background: linear-gradient(to bottom,#040C2E,rgba(4,12,46,0));
                    margin-right:23.27px;
                    overflow: hidden;
                    cursor: pointer;
                    &-content{
                        position: absolute;
                        width: 100%;
                        padding-left:34.05px;
                        box-sizing: border-box;
                        top: 45.13px;
                        left: 0;
                        z-index: 2;
                        h1{
                            font-size: 40px;
                            line-height: 58px;
                            color: #fff;
                            font-weight: 700;
                            letter-spacing: r(3);
                            white-space:pre-line;
                        }
                        .h1Eng{
                            font-size: 36px;
                        }
                        p{
                            font-size: 24px;
                            line-height:35px;
                            font-weight:300;
                            color: rgba(255,255,255,.5);
                            width: 124px;
                            margin:21.72px 0 0;
                            white-space: pre-line;
                        }
                        .next{
                            margin-top: 44px;
                            width:45.4px;
                            color: #E15100;
                        }
                    }
                    
                    &-bg{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width:100%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .industry-item:last-child{
                    margin-right: 0;
                }
            }
        }
        .new{
            width: 100%;
            background: #F0F4FA;
            padding: 100px 0 85px;
            box-sizing: border-box;
            &-head{
                width: 1200px;
                margin: 0 auto;
                p{
                    font-size:30px;
                    color: #E15100;
                    line-height: 43px;
                }
                h1{
                    font-size:60px;
                    line-height: 87px;
                    margin: 7px 0 64px;
                    color: #273443;
                }
            }
            &-list{
                width:1197px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
                .new-item{
                    width:575px;
                    height: 214px;
                    background-color: #fff;
                    display: flex;
                    cursor: pointer;
                    &-l{
                        width: 192.01px;
                        height:214px;
                    }
                    &-r{
                        width: 338px;
                        margin-left:15.59px;   
                        .new-itemR-head{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 21px 6.15px 49px 0;
                            .head-new{
                                width:76.02px;
                                height: 28px;
                                background: url('../../assets//img/juxing.png') no-repeat;
                                background-size: 100% 100%;
                                font-size: 20px;
                                line-height:28px;
                                text-indent:14.57px;
                                color:#fff;
                            }
                            .head-next{
                                width: 41.58px;
                            }
                        }
                        .new-itemR-time{
                            font-size:24px;
                            line-height: 35px;
                            color: #273443;
                            font-weight: bold;
                        }
                        p{
                          line-height: 35px;
                          font-size: 24px;
                          color:#273443; 
                          opacity: 0.5;
                          @include textWS;
                          margin-top:9px;
                          width: 338px;
                        }
                    }
                }
            }
        }
        .serve{
            width: 100%;
            height: 353pxr(353);
            position: relative;
            &-bg{
                width: 100%;
                height: 100%;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            &-bg::before{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                content: "";
                z-index: 1;
                background: linear-gradient(#0A52BA 0%,rgba(68,132,224,0) 100%);
            }
            .serve-content{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                z-index: 2;
                .serve-l{
                    width: r(146);
                    height: r(146);
                    margin-left: r(167);
                    background-color: #fff;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: r(122.45);
                        height: r(122.45);
                    }
                }
                .serve-r{
                    margin-left: r(65);
                    h1{
                        line-height: r(101);
                        font-size: r(70);
                        font-weight: bold;
                        color: #F7F9FC;
                        white-space: pre-line;
                    }
                    p{
                        margin-top: r(9);
                        line-height: r(45);
                        color: #F7F9FC;
                        font-size: r(30);
                    }
                }
            }
        }
    }
}
@keyframes rotaes{
    0%{transform:rotateY(0)}
    100%{transform:rotateY(180deg)}
}
</style>